import useFetch from './useFetch';

const useCourseContent = (url) => {
  const { data: courseContent, error } = useFetch(url, {
    headers: {
      'accept': 'text/html',
    },
  });

  return { courseContent, error };
};

export default useCourseContent;