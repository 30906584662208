import { v4 as uuid } from 'uuid';

const NavbarDefaultRoutes = [
	{
		id: uuid(),
		menuitem: 'Kurslar',
		link: 'https://xorazmiy.dev/#courses',
	},
	{
		id: uuid(),
		menuitem: 'Jamoa',
		link: 'https://xorazmiy.dev/#team',
	},
	{
		id: uuid(),
		menuitem: 'Tarif Rejalar',
		link: 'https://xorazmiy.dev/#pricing',
	},
	{
		id: uuid(),
		menuitem: 'FAQ',
		link: 'https://xorazmiy.dev/#faq',
	}
];

export default NavbarDefaultRoutes;