import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Navbar, Nav, Container, Form } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

// import sub layout components
import NavDropdownMain from 'layouts/marketing/navbars/NavDropdownMain';
import DarkLightMode from 'layouts/DarkLightMode';

// import media files
import Logo from 'assets/images/logo/logo-blue.svg';

// import data files
import NavbarDefaultRoutes from 'routes/NavbarDefault';

const NavbarDefault = ({ headerstyle, login }) => {
	const isDesktop = useMediaQuery({
		query: '(min-width: 1224px)'
	});
	const isLaptop = useMediaQuery({
		query: '(min-width: 1024px)'
	});

	const [expandedMenu, setExpandedMenu] = useState(false);

	const logoStyles = {
		width: '50px',
		height: '50px'
	};

	// Define the gtag_singup_click_conversion function
	const gtag_singup_click_conversion = (url) => {
		// Add your tracking code logic here
		console.log(`Tracking signup click for URL: ${url}`);
		// Continue to the URL
		window.location.href = url;
	};

	return (
		<Fragment>
			<Navbar
				onToggle={(collapsed) => setExpandedMenu(collapsed)}
				expanded={expandedMenu}
				expand="lg"
				className="navbar p-2 navbar-default py-2 fixed-top"
				style={{ zIndex: '1030'}}	
				>
				<Container fluid className="px-0 ps-2">
					<Navbar.Brand as={Link} to="/">
						<Image src={Logo} alt="Logo" style={logoStyles} />
					</Navbar.Brand>
					<div
						className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${
							login ? (isDesktop || isLaptop ? 'd-none' : 'd-flex') : 'd-none'
						}`}
					>
					</div>
					<Navbar.Toggle aria-controls="basic-navbar-nav">
						<span className="icon-bar top-bar mt-0"></span>
						<span className="icon-bar middle-bar"></span>
						<span className="icon-bar bottom-bar"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav>
							{NavbarDefaultRoutes.map((item, index) => {
								if (item.children === undefined) {
									return (
										<Nav.Link key={index} as={Link} to={item.link}>
											{item.menuitem}
										</Nav.Link>
									);
								} else {
									return (
										<NavDropdownMain
											item={item}
											key={index}
											onClick={(value) => setExpandedMenu(value)}
										/>
									);
								}
							})}
						</Nav>
						<Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
							<DarkLightMode />
							<li className="nav-item">
								<a 
									className="btn btn-outline-primary ms-3 fw-bold fs-6" 
									target="_blank" 
									rel="noopener noreferrer"
									href="https://my.xorazmiy.dev/" 
									onClick={(e) => {
										e.preventDefault(); // Prevent the default anchor behavior
										gtag_singup_click_conversion('https://my.xorazmiy.dev/');
									}}
									style={{ marginRight: '20px' }} // Inline style for right margin
								>
									Kirish
								</a>
							</li>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<style>
				{`
					body {
						padding-top: 70px;
						{/* overscroll-behavior-y: none;  */}
        			}
				`}
			</style>
		</Fragment>
	);
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
	headerstyle: 'navbar-default',
	login: false
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
	headerstyle: PropTypes.string,
	login: PropTypes.bool
};

export default NavbarDefault;