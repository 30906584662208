import React, { useEffect } from 'react';

const LessonContent = ({ lessonContent, contentError }) => {
  useEffect(() => {
    const lessonContainer = document.getElementById('lesson-container');

    if (lessonContainer) {
      lessonContainer.innerHTML = lessonContent;

      const scripts = lessonContainer.querySelectorAll('script');

      scripts.forEach((oldScript) => {
        const newScript = document.createElement('script');
        Array.from(oldScript.attributes).forEach(attr =>
          newScript.setAttribute(attr.name, attr.value)
        );
        newScript.textContent = oldScript.textContent; 

        oldScript.parentNode.replaceChild(newScript, oldScript);
      });
    }
  }, [lessonContent]);

  if (contentError) {
    return <div>Error loading lesson content: {contentError}</div>;
  }

  return <div id="lesson-container"></div>;
};

export default LessonContent;