import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Container, Nav, Card, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import GKAccordionDefault from 'components/common/accordions/GKAccordionDefault';
import useCourseContent from 'hooks/useCourseContent';
import useCourseIndex from 'hooks/useCourseIndex';
import CourseHeader from './course-single/CourseHeader';
import CourseContent from './course-single/CourseContent';
import CourseSidebar from './course-single/CourseSidebar';
import Footer from 'layouts/marketing/footers/Footer';
import { CONTENT_API_BASE_URL } from 'config';

const CoursePage = () => {
  const [activeTab, setActiveTab] = useState('contents');

  const { courseId } = useParams();

  const { courseContent, error: contentError } = useCourseContent(`${CONTENT_API_BASE_URL}/courses/${courseId}/content`);
  const { courseIndex, error: indexError } = useCourseIndex(`${CONTENT_API_BASE_URL}/courses/${courseId}/lessons`);

  useEffect(() => {
    if (activeTab === 'contents' && !courseIndex) {
    } else if (activeTab === 'description' && !courseContent) {
    }
  }, [activeTab, courseIndex, courseContent]);

  if (contentError || indexError) {
    return <div>Error loading course data.</div>;
  }

  return (
    <Fragment>
      <CourseHeader />
      <section className="pb-10">
        <Container>
          <Row>
            <Col lg={8} md={12} sm={12} className="mt-n8 mb-4 mb-lg-0">
              <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                <Card>
                  <Nav className="nav-lb-tab">
                    {['Contents', 'Description'].map((item, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          href={`#${item.toLowerCase()}`}
                          eventKey={item.toLowerCase()}
                          className="mb-sm-3 mb-md-0"
                        >
                          {item}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Card.Body className="p-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="contents" className="pb-4 pt-3 px-4">
                        {courseIndex ? (
                          <GKAccordionDefault
                            accordionItems={courseIndex}
                            itemClass="px-0"
                            courseId={courseId}
                          />
                        ) : (
                          <p>Loading content...</p>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="description" className="pb-4 p-4">
                        {courseContent ? (
                          <CourseContent content={courseContent} />
                        ) : (
                          <p>Loading content...</p>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </Tab.Container>
            </Col>
            <Col lg={4} md={12} sm={12} className="mt-lg-n22">
              <CourseSidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Fragment>
  );
};

export default CoursePage;