const parseResponse = (response) => {
  console.log('Raw Response:', response); // Log the raw response for debugging

  if (!response.sections) {
    console.error('Response does not contain sections');
    return [];
  }

  const CourseIndex = response.sections.map((section, index) => {
    console.log(`Processing Section ${index + 1}:`, section); // Log each section being processed
    return {
      id: index + 1,
      title: section.title, // Use section title
      content: section.content,
      topics: section.lessons.map(lesson => {
        console.log(`Processing Lesson ${lesson.id}:`, lesson); // Log each lesson being processed
        return {
          id: lesson.id,
          topic: lesson.title,
          locked: false 
        };
      })
    };
  });

  console.log('Parsed CourseIndex:', CourseIndex); // Log the parsed data for debugging

  return CourseIndex;
};

export default parseResponse;