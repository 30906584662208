import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, useAccordionButton, AccordionContext, ListGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCircleOutline } from '@mdi/js';

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Fragment>
      <Link
        to="#"
        onClick={decoratedOnClick}
        aria-expanded={isCurrentEventKey}
        className="d-flex align-items-center text-inherit text-decoration-none h4 mb-0"
        data-bs-toggle="collapse"
        aria-controls="courseTwo"
      >
        <div className="me-auto">{children.title}</div>
        <span className="chevron-arrow ms-4">
          <i className="fe fe-chevron-down fs-4"></i>
        </span>
      </Link>
    </Fragment>
  );
};

const GKAccordionDefault = ({ accordionItems = [], itemClass, courseId }) => {
  return (
    <Fragment>
      <Accordion defaultActiveKey={accordionItems[0]?.id}>
        <ListGroup as="ul" variant="flush">
          {accordionItems.map((item, index) => (
            <ListGroup.Item
              key={index}
              as="li"
              className={`${itemClass ? itemClass : ''}`}
            >
              <ContextAwareToggle eventKey={item.id}>
                {item}
              </ContextAwareToggle>
              <Accordion.Collapse eventKey={item.id}>
                <ListGroup className="py-4" as="ul">
                  {(item.topics || []).map((subitem, subindex) => (
                    <ListGroup.Item
                      key={subindex}
                      as="li"
                      disabled={subitem.locked}
                      className="px-0 py-1 border-0"
                    >
                      <Link
                        to={`/kurslar/${courseId}/${subitem.id}`}
                        className={`d-flex justify-content-between align-items-center text-inherit text-decoration-none`}
                      >
                        <div className="text-truncate">
                          <span className="icon-shape bg-light icon-sm rounded-circle me-2">
                            {subitem.locked ? (
                              <i className="fe fe-lock fs-4"></i>
                            ) : (
                              <Icon path={mdiCircleOutline} size={1} />
                            )}
                          </span>
                          <span className="fs-5">{subitem.topic}</span>
                        </div>
                      </Link>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Collapse>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Accordion>
    </Fragment>
  );
};

export default GKAccordionDefault;
