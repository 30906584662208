// src/components/Course/CourseHeader.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const CourseHeader = () => (
  <section className="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
    <Container>
      <Row className="align-items-center">
        <Col xl={7} lg={7} md={12} sm={12}>
          <div>
            <h1 className="text-white display-4 fw-semi-bold">
              Getting Started with JavaScript
            </h1>
            <p className="text-white mb-6 lead">
              JavaScript is the popular programming language which powers
              web pages and web applications. This course will get you
              started coding in JavaScript.
            </p>
            <div className="d-flex align-items-center">
              <span className="text-white ms-4 d-none d-md-block">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3"
                    y="8"
                    width="2"
                    height="6"
                    rx="1"
                    fill="#DBD8E9"
                  ></rect>
                  <rect
                    x="7"
                    y="5"
                    width="2"
                    height="9"
                    rx="1"
                    fill="#DBD8E9"
                  ></rect>
                  <rect
                    x="11"
                    y="2"
                    width="2"
                    height="12"
                    rx="1"
                    fill="#DBD8E9"
                  ></rect>
                </svg>{' '}
                <span className="align-middle">Intermediate</span>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default CourseHeader;