import useFetch from './useFetch';
import parseResponse from '../api/parseCourseResponse';

const useCourseIndex = (url) => {
  const { data, error } = useFetch(url, {
    headers: {
      'accept': 'application/json',
    },
  });

  const courseIndex = data ? parseResponse(data) : null;

  return { courseIndex, error };
};

export default useCourseIndex;