import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row, Container, Button } from 'react-bootstrap';
import useCourseIndex from 'hooks/useCourseIndex';
import useLessonContent from 'hooks/useLessonContent';
import LessonContent from 'components/LessonContent';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import GKAccordionDefault from 'components/common/accordions/GKAccordionDefault';
import { CONTENT_API_BASE_URL } from 'config';

const LessonPage = () => {
  const { courseId, lessonId } = useParams();
  const { courseIndex, error: indexError, loading: indexLoading } = useCourseIndex(`${CONTENT_API_BASE_URL}/courses/${courseId}/lessons`);
  const { lessonContent, contentError, loading: contentLoading } = useLessonContent(courseId, lessonId);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); 

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const paddingLeft = isSidebarVisible ? '' : (screenWidth >= 1024 ? '250px' : screenWidth >= 768 ? '40px' : '20px');
  const paddingRight = isSidebarVisible ? '' : (screenWidth >= 1024 ? '250px' : screenWidth >= 768 ? '40px' : '20px');

  const sections = Array.isArray(courseIndex) ? courseIndex : [];

  const lessonsArray = sections.flatMap(section => section.topics || []);

  const currentLessonIndex = lessonsArray.findIndex(lesson => lesson.id === lessonId);
  const nextLesson = currentLessonIndex < lessonsArray.length - 1 ? lessonsArray[currentLessonIndex + 1] : null;
  const prevLesson = currentLessonIndex > 0 ? lessonsArray[currentLessonIndex - 1] : null;

  const handleNextLesson = () => {
    if (nextLesson) {
      navigate(`/kurslar/${courseId}/${nextLesson.id}`);  
    }
  };
  
  const handlePrevLesson = () => {
    if (prevLesson) {
      navigate(`/kurslar/${courseId}/${prevLesson.id}`);  
    }
  };

  if (indexLoading || contentLoading) {
    return <div>Loading...</div>;
  }

  if (indexError || !courseIndex) {
    return <div>Error loading course data.</div>;
  }

  return (
    <Fragment>
      <Container fluid className="mt-lg-6">
        <Row>
          <Col
            xs={12}
            md={isSidebarVisible ? 4 : 0}
            className={`d-none d-md-block position-relative ${isSidebarVisible ? '' : 'collapsed-sidebar'}`}
            style={{ width: isSidebarVisible ? '300px' : '0' }} 
          >
            {isSidebarVisible && (
              <div style={{ position: 'sticky', top: '20px' }}>
                <SimpleBar style={{ maxHeight: '80vh' }}>
                  <h4 className="mb-3">Table of Content</h4>
                  <GKAccordionDefault 
                    accordionItems={courseIndex}
                    courseId={courseId}
                  />
                </SimpleBar>
              </div>
            )}
            <Button
              onClick={toggleSidebar}
              className="sidebar-toggle-button"
              style={{
                position: 'absolute',
                top: '0px',
                right: isSidebarVisible ? '-5px' : 'auto',
                left: isSidebarVisible ? 'auto' : '5px',
                zIndex: 1000,
                transition: 'right 0.3s ease-in-out, left 0.3s ease-in-out',
              }}
            >
              <i className={`fe fe-chevron-${isSidebarVisible ? 'left' : 'right'}`}></i>
            </Button>
          </Col>
          <Col
            xs={12}
            md={isSidebarVisible ? 8 : 12}
            className={`content-col ${isSidebarVisible ? '' : 'collapsed-content'}`}
            style={{
              marginLeft: isSidebarVisible ? '' : 'auto',
              marginRight: isSidebarVisible ? '' : 'auto',
              paddingLeft: paddingLeft,
              paddingRight: paddingRight,
            }}
          >
            <LessonContent lessonContent={lessonContent} contentError={contentError} />

            <div className="d-flex justify-content-between mt-4 mb-4">
              {prevLesson && (
                <Button variant="secondary" onClick={handlePrevLesson}>
                  Oldingi dars
                </Button>
              )}
              {nextLesson && (
                <Button variant="primary" onClick={handleNextLesson}>
                  Keyingi dars
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LessonPage;