// src/components/Course/CourseSidebar.js
import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CourseJavascript from 'assets/images/course/course-javascript.jpg';

const CourseSidebar = () => (
  <>
    <Card className="mb-3 mb-4">
      <div className="p-1">
        <div
          className="d-flex justify-content-center position-relative rounded py-10 border-white border rounded-3 bg-cover"
          style={{
            background: `url(${CourseJavascript})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
        </div>
      </div>

      {/* Card body */}
      <Card.Body>
        {/* Price single page */}
        <div className="mb-3">
          <span className="text-dark fw-bold h2 me-2">$600</span>
          <del className="fs-4 text-muted">$750</del>
        </div>
        <div className="d-grid">
          <Link
            to="/marketing/pages/pricing/"
            className="btn btn-primary"
          >
            Get Full Access
          </Link>
        </div>
      </Card.Body>
    </Card>
    {/* Card */}
    <Card className="mb-4">
      {/* Card header */}
      <Card.Header>
        <h4 className="mb-0">What’s included</h4>
      </Card.Header>
      {/* Card Body */}
      <Card.Body className="p-0">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <i className="fe fe-play-circle align-middle me-2 text-primary"></i>
            12 hours video
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="fe fe-award me-2 align-middle text-success"></i>
            Certificate
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="fe fe-calendar align-middle me-2 text-info"></i>
            12 Article
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="fe fe-video align-middle me-2 text-secondary"></i>
            Watch Offline
          </ListGroup.Item>
          <ListGroup.Item className="bg-transparent">
            <i className="fe fe-clock align-middle me-2 text-warning"></i>
            Lifetime access
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  </>
);

export default CourseSidebar;