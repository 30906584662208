import { Route, Routes, Navigate } from 'react-router-dom';
import 'assets/scss/theme.scss';

import DefaultLayout from 'layouts/marketing/DefaultLayout';
import BlankLayout from 'layouts/marketing/BlankLayout';
import NotFound from 'layouts/marketing/NotFound';

import CoursePage from 'pages/courses/CoursePage';
import LessonPage from 'pages/courses/LessonPage';

import BlogListing from 'components/blog/BlogListing';
import BlogArticleSingle from 'components/blog/BlogArticleSingle';
import BlogCategory from 'components/blog/BlogCategory';

import ComingSoon from 'pages/specialty/ComingSoon';
import Error404 from 'pages/specialty/Error404';
import MaintenanceMode from 'pages/specialty/MaintenanceMode';
import TermsAndConditions from 'pages/specialty/TermsAndConditions';

const AllRoutes = () => {
	return (
		<Routes>
			{/* Routes with DefaultLayout */}
			<Route element={<DefaultLayout />}>
				<Route
					path="/kurslar/:courseId"
					element={<CoursePage />}
				/>
				<Route
					path="/blog/:id"
					element={<BlogArticleSingle />}
				/>
				<Route path="/blog/category/:categoryId" element={<BlogCategory />} />
				<Route path="/blog" element={<BlogListing />} />
				<Route 
					exact path="/kurslar/:courseId/:lessonId" 
					element={<LessonPage />} 
				/>
				<Route 
					exact path="/courses/:courseId/:lessonId" 
					element={<LessonPage />} 
				/>
			</Route>

			{/* Routes with BlankLayout */}
			<Route element={<BlankLayout />}>
				<Route
					path="/courses/:courseId/resume"
					element={<LessonPage />}
				/>
				<Route
					path="/terms-and-conditions"
					element={<TermsAndConditions />}
				/>
			</Route>

			{/* Routes with NotFound */}
			<Route element={<NotFound />}>
				<Route
					path="/coming-soon"
					element={<ComingSoon />}
				/>
				<Route path="/404" element={<Error404 />} />
				<Route
					path="/maintenance-mode"
					element={<MaintenanceMode />}
				/>
			</Route>
		</Routes>
	);
};

export default AllRoutes;